import React, { useState } from 'react';
import styles from '../styles/AboutUs.module.css';
import Member from './components/Member';

function AboutUs() {

    const [selectedMember, setSelectedMember] = useState(null); // Selected member

    const handleMemberClick = (member) => {
        setSelectedMember(member); // Select a member
    };

    const closePopup = () => {
        setSelectedMember(null); // Close the popup
    };

    return (
        <section className={styles.container}>
            {/* Company Overview Section */}
            <div className={styles.companyOverview}>
                <h2 className={styles.sectionTitle}>Company Overview</h2>
                <ul className={styles.infoList}>
                    <li><strong>Company Name:</strong> Parnas Inc.</li>
                    <li><strong>CEO:</strong> Takahiro Yamashita</li>
                    <li><strong>Business:</strong> CG Video Production</li>
                    <li><strong>Founded:</strong> March 2022</li>
                    <li><strong>Address:</strong> Keio Shinagawa Building 2F, Room 10, 2-17-1 Konan, Minato-ku, Tokyo</li>
                </ul>
            </div>

            {/* CEO Message Section */}
            <div className={styles.ceoMessage}>
                <h2 className={styles.sectionTitle}>Message from the CEO</h2>
                <div className={styles.ceoContent}>
                    <img src="/images/member/yamashita.png" alt="CEO Takahiro Yamashita" className={styles.ceoImage} />
                    <p className={styles.ceoText}>
                        We provide advanced services specializing in rigging.<br />
                        To create opportunities for creators across Japan in the highly specialized and challenging field of rigging, we operate fully remotely.<br />
                        This is how Parnas was born.<br />
                        Our team works together to refine our skills and bring inspiration to people around the world who eagerly await Japanese CG content.
                    </p>
                </div>
            </div>

            <Member />

        </section>
    );
}

export default AboutUs;

import React, { useState } from 'react';
import styles from '../styles/ContactPage.module.css';

function ContactPage() {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <>
        <div className={styles.contactContainer}>
        <section className={styles.contactSection}>
            <div className={styles.header}>
                <h2>Contact Us</h2>
                <p>If you have any questions or inquiries, please use the form below.</p>
            </div>
            <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSfMQgXf-si5DP4fLUBQxX8BvCDMNmVHwOdW-ysm7cSbM2WF9w/viewform?embedded=true" 
                width="100%" 
                height="864" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0"
            >
                Loading…
            </iframe>
        </section>
        </div>
        </>
    );
}

export default ContactPage;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Navbar.module.css";
import logo from "../assets/logo_wide.png";

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <div className={styles.navigation}>
            <nav>
                <Link to="/" onClick={closeMenu}>
                    <img src={logo} alt="MyLogo" className={styles.logoImage} />
                </Link>

                {/* ハンバーガーアイコン */}
                <div
                    className={`${styles.hamburger} ${menuOpen ? styles.open : ""}`}
                    onClick={toggleMenu}
                >
                    <span className={styles.bar}></span>
                </div>

                <ul className={`${styles.navLinks} ${menuOpen ? styles.showMenu : ""}`}>
                    <li>
                        <Link to="/Aboutus" onClick={closeMenu}>
                            会社概要
                        </Link>
                    </li>
                    <li>
                        <Link to="/work" onClick={closeMenu}>
                            ワークス
                        </Link>
                    </li>
                    <li>
                        <Link to="/recruit" onClick={closeMenu}>
                            採用情報
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={closeMenu}>
                            お問い合わせ
                        </Link>
                    </li>
                    <li>
                        <Link to="/en" onClick={closeMenu}>
                            English
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Navbar;

import React from 'react';
import styles from '../styles/VideoContainer.module.css';

function VideoContainer() {
  return (
    <div className={styles.videoContainer}>
      <video className={styles.videoElement} src="Parnaskun_forHP_0815.mp4" autoPlay loop muted playsInline />
    </div>
  );
}

export default VideoContainer;

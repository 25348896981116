import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import useScrollToTop from "./useScrollToTop"; // カスタムフック

import './App.css';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Recruit from './pages/RecruitPage';
import RecruitInfo from './pages/RecruitInfo';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactPage from './pages/Contact';
import Works from './pages/Works';
import PrivacyPolicy from './pages/PrivacyPolicy';

// English
import EnHome from './english/Home';
import EnAboutUs from './english/AboutUs';
import EnRecruit from './english/RecruitPage';
import EnRecruitInfo from './english/RecruitInfo';
import EnNavbar from './english/components/Navbar';
import EnFooter from './english/components/Footer';
import EnContactPage from './english/Contact';
import EnWorks from './english/Works';
import EnPrivacyPolicy from './english/PrivacyPolicy';

function Layout({ children }) {
  const location = useLocation();
  const isEnglish = location.pathname.startsWith("/en");

  return (
    <>
      {isEnglish ? <EnNavbar /> : <Navbar />}
      {children}
      {isEnglish ? <EnFooter /> : <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTopWrapper>
        <Layout>
          <Routes>
            {/* 日本語ページ */}
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/work" element={<Works />} />
            <Route path="/recruit" element={<Recruit />} />
            <Route path="/recruitInfo" element={<RecruitInfo />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

            {/* 英語ページ */}
            <Route path="/en" element={<EnHome />} />
            <Route path="/en/aboutus" element={<EnAboutUs />} />
            <Route path="/en/work" element={<EnWorks />} />
            <Route path="/en/recruit" element={<EnRecruit />} />
            <Route path="/en/recruitInfo" element={<EnRecruitInfo />} />
            <Route path="/en/contact" element={<EnContactPage />} />
            <Route path="/en/privacyPolicy" element={<EnPrivacyPolicy />} />
          </Routes>
        </Layout>
      </ScrollToTopWrapper>
    </Router>
  );
}

// スクロールをリセットするカスタムラップコンポーネント
function ScrollToTopWrapper({ children }) {
  useScrollToTop(); // Router の内部で実行
  return <>{children}</>;
}

export default App;

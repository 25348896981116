import React from 'react';

// components
import Video from './components/VideoContainer';
import TextOverlay from './components/TextOverlay';
import News from './components/News'
import styles from '../styles/Home.module.css';
import CompanyOverview from './components/CompanyOverview';
import Feature from './components/Feature'; // Featureコンポーネントをインポート
import WhatIsRigging from './components/WhatIsRigging'; // 新しく追加
import Recruit from './components/Recruit'; // 新規追加
import Contact from './components/Contact'; // Contactコンポーネントをインポート




function Home() {
    return (
        <>
            <div className={styles.homeContainer}>
                <div className={styles.homeWrap}>
                    <TextOverlay />
                    <Video />
                </div>
                {/* ニュースセクションを追加 */}
                <div className={styles.newsSection}>
                    <News />
                </div>
                {/* 会社概要セクションを追加 */}
                <div className={styles.companyOverview}>
                    <CompanyOverview />
                </div>
                {/* What is Rigging? セクション */}
                {/* <div className={styles.whatIsRigging}>
                    <WhatIsRigging />
                </div> */}
                {/* Featureセクションを追加 */}
                <div className={styles.featureSection}>
                    <Feature />
                </div>
                {/* Recruit セクション */}
                <div className={styles.recruitSection}>
                    <Recruit />
                </div>
                {/* お問い合わせセクション */}
                {/* <div className={styles.contactSection}>
                    <Contact />
                </div> */}
            </div>

        </>
    );
}
export default Home;
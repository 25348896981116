// TextOverlay.js
import React from 'react';
import styles from '../styles/TextOverlay.module.css';

import logo from '../assets/logo_grey_text.png';          // ロゴ画像をインポート


function TextOverlay() {
  return (
    <div className={styles.textOverlay}>
      {/* <h1>Welcome to Our Site</h1> */}
      <img src={logo} alt="MyLogo" className={styles.logoImage} /> {/* ロゴ画像を挿入 */}
      <h1>全てのデジタルアセットに命を吹き込む</h1>
    </div>
  );
}

export default TextOverlay;

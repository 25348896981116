import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                {/* 左側: ロゴと基本情報 */}
                <div className={styles.leftSection}>
                    <img src="/logo_wide.png" alt="Parnas Logo" className={styles.logoImage} />
                    <Link to="/privacyPolicy" className={styles.link}>
                        <p className={styles.privacyPolicy}>プライバシーポリシー</p>
                    </Link>
                    <p className={styles.copyright}>© 2024 Parnas inc.</p>
                </div>

                {/* 右側: ナビゲーションリンク */}
                <div className={styles.rightSection}>
                    <nav className={styles.navLinks}>
                        <a href="/aboutUs" className={styles.navLink}>会社概要</a>
                        <a href="/work" className={styles.navLink}>ワークス</a>
                        <a href="/recruit" className={styles.navLink}>採用情報</a>
                        <a href="/contact" className={styles.navLink}>お問い合わせ</a>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React from "react";
import styles from "../styles/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>プライバシーポリシー</h1>

      <section>
        <h2 className={styles.sectionTitle}>第1条（個人情報）</h2>
        <p className={styles.text}>
          「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述などにより特定の個人を識別できるものを指します。
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>第2条（個人情報の収集方法）</h2>
        <p className={styles.text}>
          当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレスなどの情報を収集することがあります。また、提携先などから適正に個人情報を取得することがあります。
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>
          第3条（個人情報を収集・利用する目的）
        </h2>
        <ul className={styles.list}>
          <li>当社サービスの提供・運営のため</li>
          <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
          <li>ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
          <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
          <li>利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</li>
          <li>ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため</li>
          <li>上記の利用目的に付随する目的</li>
        </ul>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>第4条（利用目的の変更）</h2>
        <p className={styles.text}>
          当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。変更後の目的については、ユーザーに通知し、または本ウェブサイト上で公表します。
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>第5条（個人情報の第三者提供）</h2>
        <p className={styles.text}>
          当社は、次に掲げる場合を除き、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
        </p>
        <ul className={styles.list}>
          <li>法令に基づく場合</li>
          <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
          </li>
          <li>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>第6条（個人情報の開示）</h2>
        <p className={styles.text}>
          当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。
          ただし、開示することにより以下のいずれかに該当する場合は、その全部または一部を開示しないことがあります。
        </p>
        <ul className={styles.list}>
          <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
          <li>
            当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          </li>
          <li>その他法令に違反することとなる場合</li>
        </ul>
      </section>

      <footer className={styles.footer}>
        <p>プライバシーポリシー © 2024 Parnas Inc.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import styles from '../styles/Feature.module.css';

function Feature() {
    const features = [
        {
            title: "リグの専門集団",
            description: "CG制作の要となるリギングに特化し、高いスピードとクオリティを提供します。キャラクターからプロップ、ビークルまで幅広い対象に対応可能です。",
        },
        {
            title: "映像制作に特化",
            description: "MayaやBlenderを活用し、アニメーション制作やゲーム開発、メタバースなど多彩な分野で力を発揮しています。",
        },
        {
            title: "全国リモート対応",
            description: "リモート環境を活用し、地域を問わず優れた人材を支援。地方のスタジオやクリエイターとも連携しています。",
        },
    ];

    return (
        <section className={styles.container}>
            <h2 className={styles.title}>Feature</h2>
            <div className={styles.featureList}>
                {features.map((feature, index) => (
                    <div key={index} className={styles.featureItem}>
                        <h3 className={styles.featureTitle}>{feature.title}</h3>
                        <p className={styles.featureDescription}>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Feature;

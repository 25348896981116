import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/RecruitInfo.module.css';

function Recruit() {
    return (
        <section className={styles.container}>
            <h1 className={styles.title}>クリエイター採用情報</h1>
            <div className={styles.gridBackground}></div> {/* 背景のグリッドデザイン */}
            <table className={styles.recruitTable}>
                <tbody>
                    <tr>
                        <th>募集職種</th>
                        <td>リギングアーティスト<br />テクニカルアーティスト</td>
                    </tr>
                    <tr>
                        <th>雇用形態</th>
                        <td>社員 / 契約社員</td>
                    </tr>
                    <tr>
                        <th>業務内容</th>
                        <td>
                            <strong>リギングアーティスト</strong><br />
                            ・映像制作、ゲーム制作、アバター制作などにおけるリギング業務全般<br />
                            ・スケルトン設定からウェイト調整、コントローラ作成<br /><br />
                            <strong>テクニカルアーティスト</strong><br />
                            ・自社ツールの開発・運用、およびクライアントツールの開発支援
                        </td>
                    </tr>
                    <tr>
                        <th>求めるスキル</th>
                        <td>
                            <strong>必須スキル/経験</strong><br />
                            ・Mayaを使用した3Dモデリングのリギング業務1年以上<br /><br />
                            <strong>歓迎スキル/経験</strong><br />
                            ・Unreal Engine / Blenderでのセットアップ経験<br />
                            ・制作進行管理経験 / チーム運営経験 / 新人メンター経験
                        </td>
                    </tr>
                    <tr>
                        <th>勤務地</th>
                        <td>完全リモート</td>
                    </tr>
                    <tr>
                        <th>勤務時間</th>
                        <td>フレックスタイム制<br />休憩時間：60分<br />コアタイム 11:30～15:30</td>
                    </tr>
                </tbody>
            </table>
            <div className={styles.buttonWrapper}>
                <Link to='/contact'>
                    <button className={styles.contactButton}>応募 / お問い合わせへ</button>
                </Link>
            </div>
        </section>
    );
}

export default Recruit;

// TextOverlay.js
import React from 'react';
import styles from '../../styles/TextOverlay.module.css';

import logo from '../../assets/logo_grey_text.png'; // Import the logo image

function TextOverlay() {
  return (
    <div className={styles.textOverlay}>
      {/* <h1>Welcome to Our Site</h1> */}
      <img src={logo} alt="MyLogo" className={styles.logoImage} /> {/* Insert the logo image */}
      <h1>Bringing Life to All Digital Assets</h1>
    </div>
  );
}

export default TextOverlay;

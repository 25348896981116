import React from 'react';
import styles from '../../styles/CompanyOverview.module.css';

function CompanyOverview() {
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h3 className={styles.subTitle}>Parnas - Parnas -</h3>
                <h1 className={styles.mainTitle}>Shaping the Future of CG with Rigging</h1>
                <p className={styles.description}>
                    We are a team of creators specializing in rigging. Rigging refers to the process of creating mechanisms that bring CG models to life, and its quality greatly influences the overall expression of CG. <br />
                    Our experienced riggers are adept at working with a wide range of subjects, from characters and creatures to props and vehicles. Utilizing advanced techniques such as expressions, driven keys, and simulations, we flexibly set up rigs that are user-friendly for animators.
                </p>
                <p className={styles.missionSummary}>
                    We aim to maximize the potential of CG and support creative expression through rigging technology. Connecting talent across locations, we leverage technology to pioneer a new future.
                </p>

                {/* <button className={styles.button}>Learn More</button> */}
                {/* Add "What is Rigging?" section */}
                <div className={styles.riggingExplanation}>
                    <h2 className={styles.riggingTitle}>What is Rigging?</h2>
                    <p className={styles.riggingDescription}>
                        "Rigging" refers to the process of creating mechanisms that allow CG models to move. It involves adding bones to polygons, setting influence areas, and creating controllers. 
                        The quality of rigging directly impacts CG expression.
                    </p>
                </div>

            </div>
            <div className={styles.imageWrapper}>
                <img src="/images/VRchara_web_normal.png" alt="Rigging explanation" className={styles.image} />
            </div>
        </section>
    );
}

export default CompanyOverview;

import React, { useState } from 'react';
import Contact from '../components/Contact';
import styles from '../styles/ContactPage.module.css';

function ContactPage() {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    return(
        <>
        <div className={styles.contactContainer}>
        <section className={styles.contactSection}>
            <div className={styles.header}>
                <h2>お問い合わせ</h2>
                <p>ご質問やご相談がございましたら、以下のフォームをご利用ください。</p>
            </div>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfMQgXf-si5DP4fLUBQxX8BvCDMNmVHwOdW-ysm7cSbM2WF9w/viewform?embedded=true" width="100%" height="864" frameborder="0" marginheight="0" marginwidth="0">
                                読み込んでいます…
                            </iframe>
        </section>
        </div>
        </>
    )
}export default ContactPage;
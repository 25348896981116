import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Recruit.module.css';

function Recruit() {
    return (
        <section className={styles.recruitSection}>
            <div className={styles.header}>
                <h2>RECRUIT</h2>
                <p>あなたの専門性を発揮しませんか？</p>
            </div>
            <div className={styles.content}>
                {/* <h3 className={styles.title}>RECRUIT</h3>
                <p className={styles.description}>
                    Parnasはリグ制作に特化したサービスを提供する専門クリエイター集団です。<br />
                    アニメーター、ゲームメーカに最適化されたリグ構築を行い、高品質なCG制作を支援します。<br />
                    日本全国からリモートでの活躍も可能です。あなたの専門性を活かして、新しい未来を築きましょう。
                </p> */}
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <h4>Parnasで働きたい方</h4>
                        <p>
                            Parnasでは現場で活躍するリガー・T.Aを募集しています。<br />
                            リギングの経験が浅い方も、適切なトレーニングを提供します。
                        </p>
                        <Link to='/recruitInfo'>
                            <button className={styles.button}>募集要項を見る</button>
                        </Link>
                    </div>
                    <div className={styles.card}>
                        <h4>提携パートナー/フリーランスの方</h4>
                        <p>
                            提携パートナーとして協力いただける方、フリーランスのリガーを募集しています。<br />
                            柔軟な働き方を希望する方に最適な案件をご提供します。
                        </p>
                        <Link to='/contact'>
                            <button className={styles.button}>フリーランスとして応募</button>

                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recruit;

import React, { useState, useEffect } from 'react';
import styles from '../styles/WorksPage.module.css';

function WorksPage() {
    const [works, setWorks] = useState([]);
    const [selectedWork, setSelectedWork] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWorks = async () => {
            try {
                const response = await fetch('/api/getWorks.php'); // APIエンドポイントに変更
                const data = await response.json();
                if (data.status === 'success') {
                    setWorks(data.data);
                } else {
                    setError('データを取得できませんでした。');
                }
            } catch (err) {
                setError('エラーが発生しました。');
            } finally {
                setLoading(false);
            }
        };
        fetchWorks();
    }, []);

    const isMobile = () => window.innerWidth <= 768;

    const handleCardClick = (work) => {
        if (isMobile() && work.type === 'video') {
            window.open(work.url.replace('embed/', 'watch?v='), '_blank');
        } else {
            setSelectedWork(work);
        }
    };

    const closePopup = () => setSelectedWork(null);

    const tools = [
        {
            name: 'Maya',
            logo: '/tools/maya.png',
        },
        {
            name: '3ds Max',
            logo: '/tools/3dsmax.png',
        },
        {
            name: 'Unity',
            logo: '/tools/unity.png',
        },
        {
            name: 'Blender',
            logo: '/tools/blender.png',
        },
    ];

    if (loading) return <p>読み込み中...</p>;
    if (error) return <p>{error}</p>;

    return (
        <section className={styles.worksSection}>
            <div className={styles.header}>
                <h2>制作実績</h2>
                <p>私たちが手掛けた最新のプロジェクトをご覧ください。</p>
            </div>
            <div className={styles.worksGrid}>
                {works.map((work, index) => (
                    <div
                        key={index}
                        className={styles.workCard}
                        onClick={() => handleCardClick(work)}
                    >
                        {work.type === 'video' ? (
                            <iframe
                                className={styles.videoFrame}
                                src={work.url}
                                title={work.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <img src={work.url} alt={work.title} className={styles.workImage} />
                        )}
                        <div className={styles.workDetails}>
                            <h3 className={styles.workTitle}>{work.title}</h3>
                            <p className={styles.workCategory}>{work.category}</p>
                            <p className={styles.workDescription}>{work.description}</p>
                            <p className={styles.workDate}>{work.date}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* ポップアップ */}
            {selectedWork && (
                <div className={styles.popupOverlay} onClick={closePopup}>
                    <div
                        className={styles.popupContent}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className={styles.closeButton} onClick={closePopup}>
                            ×
                        </button>
                        {selectedWork.type === 'video' ? (
                            <iframe
                                className={styles.popupVideo}
                                src={selectedWork.url}
                                title={selectedWork.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <img
                                src={selectedWork.url}
                                alt={selectedWork.title}
                                className={styles.popupImage}
                            />
                        )}
                    </div>
                </div>
            )}

            {/* 対応ツールセクション */}
            <div className={styles.toolsSection}>
                <h3 className={styles.toolsTitle}>対応ツール</h3>
                <div className={styles.toolsGrid}>
                    {tools.map((tool, index) => (
                        <div key={index} className={styles.toolCard}>
                            <img
                                src={tool.logo}
                                alt={tool.name}
                                className={styles.toolLogo}
                            />
                            <p className={styles.toolName}>{tool.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default WorksPage;

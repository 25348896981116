import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../styles/RecruitPage.module.css';

function Recruit() {
    return (
        <section className={styles.recruitSection}>
            {/* Header Section */}
            <div className={styles.header}>
                <h2>Revolutionizing the CG Industry</h2>
                <p>Would you like to showcase your expertise?</p>
            </div>

            {/* Content */}
            <div className={styles.content}>
                <h3 className={styles.title}>What is Parnas Inc.?</h3>
                <p className={styles.description}>
                    Parnas is a team of specialized creators offering services focused on rigging.<br />
                    We build rigs optimized for animators and game developers, supporting high-quality CG production.<br />
                    You can work remotely from anywhere in Japan. Let's utilize your expertise to create a new future together.
                </p>

                {/* Card Layout */}
                <div className={styles.cards}>
                    {/* First Card */}
                    <div className={styles.card}>
                        <div className={styles.cardHeader}>
                            <h4>Join Parnas</h4>
                        </div>
                        <p>
                            Parnas is looking for riggers and technical artists (T.A.) who can contribute to our projects.<br />
                            Even if you are new to rigging, we provide appropriate training to help you grow.
                        </p>
                        <Link to="/en/recruitInfo">
                            <button className={styles.button}>View Recruitment Details</button>
                        </Link>
                    </div>

                    {/* Second Card */}
                    <div className={styles.card}>
                        <div className={styles.cardHeader}>
                            <h4>Partners/Freelancers</h4>
                        </div>
                        <p>
                            We are seeking partners and freelance riggers to collaborate with us.<br />
                            We offer ideal projects for those who prefer flexible working styles.
                        </p>
                        <button className={styles.button}>Apply as a Freelancer</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recruit;

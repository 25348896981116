import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/Recruit.module.css';

function Recruit() {
    return (
        <section className={styles.recruitSection}>
            <div className={styles.header}>
                <h2>RECRUIT</h2>
                <p>Would you like to showcase your expertise?</p>
            </div>
            <div className={styles.content}>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <h4>Join Parnas</h4>
                        <p>
                            Parnas is looking for riggers and T.A.s who can contribute to our projects.<br />
                            Even if you are new to rigging, we offer appropriate training to help you grow.
                        </p>
                        <Link to='/en/recruitInfo'>
                            <button className={styles.button}>View Recruitment Details</button>
                        </Link>
                    </div>
                    <div className={styles.card}>
                        <h4>Partnerships/Freelancers</h4>
                        <p>
                            We are looking for freelance riggers and partners to collaborate with us.<br />
                            We provide ideal projects for those seeking flexible working styles.
                        </p>
                        <Link to='/en/contact'>
                            <button className={styles.button}>Apply as a Freelancer</button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Recruit;

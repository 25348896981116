import React from 'react';
import styles from '../styles/WhatIsRigging.module.css';

function WhatIsRigging() {
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.title}>What is Rigging?</h2>
                <p className={styles.description}>
                    "リギング"とはCGモデルを動かす仕組み作りを指します。
                    ポリゴンに対して骨を仕込み、影響度を設定し、コントローラーを作成します。
                    リギングの巧拙はCG表現に直結します。
                </p>
            </div>
        </section>
    );
}

export default WhatIsRigging;

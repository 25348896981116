import React, { useState, useEffect } from 'react';
import styles from '../../styles/News.module.css';

function News() {
    const [newsItems, setNewsItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAll, setShowAll] = useState(false); // Flag to show all news items

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch('/api/getNewsEnglish.php'); // Update to the correct API endpoint
                const data = await response.json();

                if (data.status === 'success') {
                    setNewsItems(data.data);
                } else {
                    setError('Failed to fetch news data.');
                }
            } catch (err) {
                setError('An error occurred.');
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    if (loading) {
        return <p className={styles.loading}>Loading...</p>;
    }

    if (error) {
        return <p className={styles.error}>{error}</p>;
    }

    // Control the number of news items displayed
    const visibleNewsItems = showAll ? newsItems : newsItems.slice(0, 3);

    return (
        <div className={styles.newsContainer}>
            <h2 className={styles.title}>News</h2>
            <div className={styles.newsGrid}>
                {visibleNewsItems.map((item, index) => (
                    <div key={index} className={styles.newsCard}>
                        <img
                            src={item.image || '/images/VRchara_web_normal.png'}
                            alt={`News Image: ${item.title_en}`}
                            className={styles.newsImage}
                        />
                        <div className={styles.newsContent}>
                            <h3>{item.title_en}</h3>
                            <p>{item.description_en}</p>
                            <a
                                href={item.link_en}
                                className={styles.newsButton}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Read More
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            {!showAll && newsItems.length > 3 && (
                <button
                    className={styles.showAllButton}
                    onClick={() => setShowAll(true)}
                >
                    Show All
                </button>
            )}
        </div>
    );
}

export default News;

import React from "react";
import styles from "../styles/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Privacy Policy</h1>

      <section>
        <h2 className={styles.sectionTitle}>Article 1 (Personal Information)</h2>
        <p className={styles.text}>
          "Personal information" refers to information about a living individual that can identify a specific person, such as name, date of birth, address, phone number, contact information, and other descriptions included in the information.
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>Article 2 (Methods of Collecting Personal Information)</h2>
        <p className={styles.text}>
          Our company may collect personal information such as name, date of birth, address, phone number, and email address during user registration. Additionally, we may acquire personal information appropriately from our partners.
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>
          Article 3 (Purpose of Collecting and Using Personal Information)
        </h2>
        <ul className={styles.list}>
          <li>To provide and operate our services</li>
          <li>To respond to user inquiries (including identity verification)</li>
          <li>To send emails about new features, updates, campaigns, and other services we provide</li>
          <li>To contact users as necessary for maintenance and important notices</li>
          <li>To identify and refuse usage by users who violate our terms of use or attempt to use our services for fraudulent or improper purposes</li>
          <li>To allow users to view, change, or delete their registration information and check their usage status</li>
          <li>To fulfill purposes related to the above</li>
        </ul>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>Article 4 (Changes to Purpose of Use)</h2>
        <p className={styles.text}>
          Our company may change the purpose of use of personal information only when it is reasonably related to the purpose before the change. If changes are made, we will notify users or announce them on this website.
        </p>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>Article 5 (Provision of Personal Information to Third Parties)</h2>
        <p className={styles.text}>
          Our company will not provide personal information to third parties without prior consent from users except in the following cases or as permitted by laws and regulations:
        </p>
        <ul className={styles.list}>
          <li>When required by law</li>
          <li>
            When it is necessary to protect a person's life, body, or property and obtaining the person's consent is difficult
          </li>
          <li>
            When it is especially necessary to improve public health or promote the sound upbringing of children, and obtaining the person's consent is difficult
          </li>
          <li>
            When cooperation is necessary for a national or local government agency or its designee to carry out legal duties, and obtaining consent may interfere with the execution of those duties
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.sectionTitle}>Article 6 (Disclosure of Personal Information)</h2>
        <p className={styles.text}>
          When requested by the person, our company will promptly disclose their personal information. However, disclosure may be withheld in whole or in part in the following cases:
        </p>
        <ul className={styles.list}>
          <li>
            When it may harm the life, body, property, or other rights and interests of the person or a third party
          </li>
          <li>When it may significantly hinder the proper execution of our business</li>
          <li>When it may violate other laws or regulations</li>
        </ul>
      </section>

      <footer className={styles.footer}>
        <p>Privacy Policy © 2024 Parnas Inc.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/RecruitInfo.module.css';

function Recruit() {
    return (
        <section className={styles.container}>
            <h1 className={styles.title}>Recruitment Information</h1>
            <div className={styles.gridBackground}></div> {/* Grid background design */}
            <table className={styles.recruitTable}>
                <tbody>
                    <tr>
                        <th>Position</th>
                        <td>Rigging Artist<br />Technical Artist</td>
                    </tr>
                    <tr>
                        <th>Employment Type</th>
                        <td>Full-time / Contract</td>
                    </tr>
                    <tr>
                        <th>Job Description</th>
                        <td>
                            <strong>Rigging Artist</strong><br />
                            ・General rigging tasks for video production, game development, avatar creation, etc.<br />
                            ・Skeleton setup, weight adjustment, and controller creation<br /><br />
                            <strong>Technical Artist</strong><br />
                            ・Development and operation of in-house tools, and support for client tool development
                        </td>
                    </tr>
                    <tr>
                        <th>Required Skills</th>
                        <td>
                            <strong>Required Skills/Experience</strong><br />
                            ・At least 1 year of rigging experience using Maya<br /><br />
                            <strong>Preferred Skills/Experience</strong><br />
                            ・Experience with setup in Unreal Engine / Blender<br />
                            ・Experience in production progress management / team leadership / mentoring newcomers
                        </td>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <td>Fully Remote</td>
                    </tr>
                    <tr>
                        <th>Working Hours</th>
                        <td>Flexible hours<br />Break time: 60 minutes<br />Core time: 11:30 AM – 3:30 PM</td>
                    </tr>
                </tbody>
            </table>
            <div className={styles.buttonWrapper}>
                <Link to='/en/contact'>
                    <button className={styles.contactButton}>Apply / Contact Us</button>
                </Link>
            </div>
        </section>
    );
}

export default Recruit;

import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                {/* Left Section: Logo and Basic Information */}
                <div className={styles.leftSection}>
                    <img src="/logo_wide.png" alt="Parnas Logo" className={styles.logoImage} />
                    <Link to="/en/privacyPolicy" className={styles.link}>
                        <p className={styles.privacyPolicy}>Privacy Policy</p>
                    </Link>
                    <p className={styles.copyright}>© 2024 Parnas Inc.</p>
                </div>

                {/* Right Section: Navigation Links */}
                <div className={styles.rightSection}>
                    <nav className={styles.navLinks}>
                        <a href="/en/aboutus" className={styles.navLink}>About Us</a>
                        <a href="/en/work" className={styles.navLink}>Works</a>
                        <a href="/en/recruit" className={styles.navLink}>Recruitment</a>
                        <a href="/en/contact" className={styles.navLink}>Contact</a>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React from 'react';
import styles from '../styles/CompanyOverview.module.css';

function CompanyOverview() {
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h3 className={styles.subTitle}>Parnas - パルナス -</h3>
                <h1 className={styles.mainTitle}>リギングでCGの未来を支える</h1>
                <p className={styles.description}>
                    私たちは、リギング専門のクリエイター集団です。リギングとは、CGモデルを動かす仕組みを作るプロセスを指し、その巧拙がCG表現の品質を左右します。<br />
                    キャラクターからクリーチャー、プロップ、ビークルまで、幅広い対象に対応可能な経験豊富なリガーが在籍しています。エクスプレッション、ドリブンキー、シミュレーションなど高度な技術を駆使し、アニメーターにとって使いやすいリグを柔軟にセットアップします。
                </p>
                <p className={styles.missionSummary}>
                    私たちはCGの可能性を最大化し、リギング技術を通じてクリエイティブな表現を支えます。場所を問わず才能を結びつけ、テクノロジーで新しい未来を切り拓いていきます。
                </p>

                {/* <button className={styles.button}>詳しく見る</button> */}
                {/* What is Rigging? セクションを追加 */}
                <div className={styles.riggingExplanation}>
                    <h2 className={styles.riggingTitle}>What is Rigging?</h2>
                    <p className={styles.riggingDescription}>
                        "リギング"とはCGモデルを動かす仕組み作りを指します。ポリゴンに対して骨を仕込み、影響度を設定し、コントローラーを作成します。
                        リギングの巧拙はCG表現に直結します。
                    </p>
                </div>


            </div>
            <div className={styles.imageWrapper}>
                <img src="/images/VRchara_web_normal.png" alt="リギングの説明" className={styles.image} />
            </div>
        </section>
    );
}

export default CompanyOverview;

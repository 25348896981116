import React, { useState, useEffect } from 'react';
import styles from '../../styles/Member.module.css';

function Member() {
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch member information from the API
    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await fetch('/api/getMembersEnglish.php'); // API endpoint
                const data = await response.json();

                if (data.status === 'success') {
                    setMembers(data.data);
                } else {
                    setError(`Server Error: ${data.message || 'Unknown error'}`);
                }
            } catch (err) {
                setError(`Client Error: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchMembers();
    }, []);

    const handleMemberClick = (member) => {
        setSelectedMember(member);
        setOpenQuestionIndex(null);
    };

    const closePopup = () => {
        setSelectedMember(null);
        setOpenQuestionIndex(null);
    };

    const toggleQuestion = (index) => {
        setOpenQuestionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    if (loading) {
        return <p className={styles.loading}>Loading...</p>;
    }

    if (error) {
        return <p className={styles.error}>{error}</p>;
    }

    return (
        <section className={styles.container}>
            <div className={styles.team}>
                <h2 className={styles.sectionTitle}>Meet Our Team</h2>
                <div className={styles.memberList}>
                    {members.map((member, index) => (
                        <div
                            key={index}
                            className={styles.member}
                            onClick={() => handleMemberClick(member)}
                        >
                            <img
                                src={member.image_path}
                                alt={member.name}
                                className={styles.memberImage}
                            />
                            <p className={styles.memberName}>{member.name}</p>
                            <p className={styles.memberRole}>{member.role}</p>
                        </div>
                    ))}
                </div>
            </div>

            {selectedMember && (
                <div className={styles.popupOverlay} onClick={closePopup}>
                    <div
                        className={styles.popupContent}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={styles.memberDetails}>
                            <img
                                src={selectedMember.image_path}
                                alt={selectedMember.name}
                                className={styles.popupImage}
                            />
                            <div className={styles.memberInfo}>
                                <h3 className={styles.popupName}>{selectedMember.name}</h3>
                                <p className={styles.popupRole}>{selectedMember.role}</p>
                                <p className={styles.popupHistory}>
                                    <strong>History:</strong>
                                    <br />
                                    {selectedMember.history}
                                </p>
                            </div>
                        </div>
                        <div className={styles.popupQnA}>
                            {selectedMember.qna.map((qa, index) => (
                                <div
                                    key={index}
                                    className={`${styles.questionBlock} ${
                                        openQuestionIndex === index ? styles.active : ''
                                    }`}
                                    onClick={() => toggleQuestion(index)}
                                >
                                    <strong>{qa.question}</strong>
                                    {openQuestionIndex === index && (
                                        <p className={styles.answer}>{qa.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

export default Member;

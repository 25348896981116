import React, {useState} from 'react';
import styles from '../styles/AboutUs.module.css';
import Member from '../components/Member';

function AboutUs() {

    const [selectedMember, setSelectedMember] = useState(null); // 選択されたメンバー
    const members = [
        {
            name: 'T. Yamashita',
            role: '代表取締役',
            image: '/images/member/web_member_VRchara_02.png',
            history: `CG歴：フリーランスとして５年活動。
                      VRChat用モデルのセットアップを経験。
                      遊技機セットアップの経験。
                      劇場作品キャラクターセットアップの経験。`,
        },
        {
            name: 'R. Yanagi',
            role: 'リグシニアアーティスト',
            image: '/images/member/web_member_VRchara_02.png',
            history: `CG歴：フリーランスとして３年活動。
                      ゲーム用キャラクターのセットアップ経験。
                      アニメーションリグ作成の専門家。`,
        },
        {
            name: 'K. Yamashita',
            role: 'テクニカルアーティスト',
            image: '/images/member/web_member_VRchara_02.png',
            history: `CG歴：リグとスクリプト制作を担当。
                      アニメ・ゲーム両方の制作経験あり。`,
        },
        // 他のメンバーを追加
    ];

    const handleMemberClick = (member) => {
        setSelectedMember(member); // メンバーを選択
    };

    const closePopup = () => {
        setSelectedMember(null); // ポップアップを閉じる
    };


    return (
        <section className={styles.container}>
            {/* 会社概要セクション */}
            <div className={styles.companyOverview}>
                <h2 className={styles.sectionTitle}>会社概要</h2>
                <ul className={styles.infoList}>
                    <li><strong>社名:</strong> 株式会社Parnas（パルナス）</li>
                    <li><strong>代表:</strong> 山下　貴裕</li>
                    <li><strong>事業:</strong> CG映像制作</li>
                    <li><strong>設立:</strong> 2022年3月</li>
                    <li><strong>住所:</strong> 東京都港区港南2-17-1 京王品川ビル2F 10号室</li>
                </ul>
            </div>

            {/* 代表挨拶セクション */}
            <div className={styles.ceoMessage}>
                <h2 className={styles.sectionTitle}>代表挨拶</h2>
                <div className={styles.ceoContent}>
                    <img src="/images/member/yamashita.png" alt="代表 山下貴裕" className={styles.ceoImage} />
                    <p className={styles.ceoText}>
                        私たちはリギングに特化した先進サービスを展開しています。<br />
                        最も人材育成が難しいリギング領域で、日本全国のクリエイターが活躍できる機会を創るためフルリモートで。<br />
                        こうしてParnasは生まれました。<br />
                        日本のCGコンテンツを心待ちにする世界中の人々に感動を届けられるよう、チーム一丸となって技術を磨きます。
                    </p>
                </div>
            </div>

            <Member />

            {/* メンバー紹介セクション */}
            {/* <div className={styles.team}>
                <h2 className={styles.sectionTitle}>メンバー紹介</h2>
                <div className={styles.memberList}>
                    {members.map((member, index) => (
                        <div
                            key={index}
                            className={styles.member}
                            onClick={() => handleMemberClick(member)} // メンバークリックイベント
                        >
                            <img src={member.image} alt={member.name} className={styles.memberImage} />
                            <p className={styles.memberName}>{member.name}</p>
                            <p className={styles.memberRole}>{member.role}</p>
                        </div>
                    ))}
                </div>
            </div> */}

            {/* ポップアップ */}
            {/* {selectedMember && (
                <div className={styles.popupOverlay} onClick={closePopup}>
                    <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                        <button className={styles.closeButton} onClick={closePopup}>×</button>
                        <img src={selectedMember.image} alt={selectedMember.name} className={styles.popupImage} />
                        <h3 className={styles.popupName}>{selectedMember.name}</h3>
                        <p className={styles.popupRole}>{selectedMember.role}</p>
                        <p className={styles.popupHistory}>
                            <strong>【経歴】</strong><br />
                            {selectedMember.history}
                        </p>
                    </div>
                </div>
            )} */}
        </section>
    );
}

export default AboutUs;

import React from 'react';
import styles from '../../styles/Feature.module.css';

function Feature() {
    const features = [
        {
            title: "Specialists in Rigging",
            description: "We specialize in rigging, the core of CG production, offering high speed and quality. From characters to props and vehicles, we can handle a wide range of subjects.",
        },
        {
            title: "Focused on Visual Production",
            description: "Using Maya and Blender, we excel in various fields such as animation production, game development, and the metaverse.",
        },
        {
            title: "Nationwide Remote Support",
            description: "Leveraging remote work environments, we support talented individuals regardless of location and collaborate with studios and creators across regions.",
        },
    ];

    return (
        <section className={styles.container}>
            <h2 className={styles.title}>Features</h2>
            <div className={styles.featureList}>
                {features.map((feature, index) => (
                    <div key={index} className={styles.featureItem}>
                        <h3 className={styles.featureTitle}>{feature.title}</h3>
                        <p className={styles.featureDescription}>{feature.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Feature;
